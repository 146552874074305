import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import img1 from "../../images/jticamel/1.png"
import img2 from "../../images/jticamel/2.png"



// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;






export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    Camel is an American cigarette brand, owned by Japan Tobacco Inc outside of the US.
                    <p/>
                    Wunderman Thompson created the "Which Camel are you"-Campaign and had me write a multple-part short story featuring several characters and a cliffhanger after every part.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>

              </Type>
        </English>

        <Deutsch Language={location.search}>
              <Type>
                    Camel ist eine amerikanische Zigarettenmarke, die ausserhalb der USA Japan Tocacco Inc gehört.
                    <p/>
                    Wunderman Thompson kreierte die "Which Camel are you"–Kampagne und liess mich eine mehrteilige Kurzgeschichte dazu schreiben, mit einem Cliffhanger nach jedem Teil.
                  <p/>
                  <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>
              </Type>
        </Deutsch>


    </div>

    <Element name="test1"></Element>





    <img src={img1}/>
    <img src={img2}/>









      <Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
